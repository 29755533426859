import React, { useMemo } from 'react';
import OfficialReceiptsStageRefund from './OfficialReceiptsStageRefund';
import InvoicesStagesPayment from '../../Invoices/Stages/InvoicesStagesPayment';


const OfficialReceiptsStages: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>
            <InvoicesStagesPayment
                data={data.invoice}
                isReceipt={true}
            />

            <OfficialReceiptsStageRefund
                data={data}
            />
        </>

    ), [data]);

}

export default OfficialReceiptsStages;
