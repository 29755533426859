import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import InvoicesApi from './InvoicesApi';
import { Status, WhiteSpace } from '@dex/bubl-dash';
import InvoiceApi from './InvoicesApi';
import { wpOldLink } from '../../functions/wpOldLink';
import OriginalModelLink from '../../elements/OriginalModelLink/OriginalModelLink';


let settings;

export default settings = {
    key: 'invoices',
    zone: 'finance',
    path: '/invoices',
    title: "Invoices",
    endpoint: InvoicesApi.endpoint,
    modelName: 'Invoices',
    idKey: 'id',
    primaryKey: 'id',
    indexCaps: ["viewInvoices"],
    viewCaps: ["viewInvoices"],
    createCaps: ["createInvoices"],
    actions: ['trash', 'clone'],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            // {
            //     label: "Add New",
            //     path: settings.path + "/create"
            // },

            {
                label: "Due",
                path: settings.path + "/status/due",
            },
            {
                label: "Partially Paid",
                path: settings.path + "/status/partially_paid",
            },
            {
                label: "Paid",
                path: settings.path + "/status/paid",
            },
            {
                label: "Void",
                path: settings.path + "/status/void",
            },
            {
                label: "Cancelled",
                path: settings.path + "/status/cancelled",
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: InvoicesApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = { where: {}, include: [], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexExportFormats: (routeName, params, user) => {

        const formats = [
            { value: "invoices", label: "Invoices" },
            { value: "invoicesComplete", label: "Invoices Complete" }
        ];

        if (!user || !user.caps.includes("exportInvoices")) return [];

        return formats;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['id', 'original_id', 'company_name'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "Invoice No.",
                key: "id",
                type: "text",
            },
            {
                label: "Company Name",
                key: "company_name",
                type: "text",
            },
            {
                label: "Description",
                key: "description",
                type: "text",
            },
            {
                label: "Date Issued",
                key: "date_issued",
                type: "date",
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "due", label: "Due" },
                    { value: "partially_paid", label: "Partially Paid" },
                    { value: "paid", label: "Paid" },
                    { value: "void", label: "Void" },
                    { value: "cancelled", label: "Cancelled" },
                ]
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Invoice No.",
                key: "this",
                format: "ModelLink",
                render: (value, row) => <OriginalModelLink data={row} />
            },
            {
                label: "Company Name",
                key: "company_name",
                width: 180,
                wrap: true,
            },
            // {
            //     label: "Type",
            //     key: "type",
            // },
            {
                label: "Description",
                key: "description",
                format: "nl2br",
                width: 300,
                wrap: true,
            },
            {
                label: "Date Issued",
                key: "date_issued",
                format: "dateDay",
            },
            {
                label: "Status",
                key: "status",
                format: "status",
                prefix: "invoices.status"
            },
            {
                label: "Sub Total",
                key: "subtotal",
                format: "number",
                prefix: "RM "
            },
            {
                label: "Deposit",
                key: "deposit",
                format: "number",
                prefix: "RM "
            },
            {
                label: "Total",
                key: "total",
                format: "number",
                prefix: "RM "
            },
            // {
            //     label: "Paid",
            //     key: "amount_paid",
            //     format: "number",
            //     prefix: "RM "
            // },
            // {
            //     label: "Balance",
            //     key: "amount_due",
            //     format: "number",
            //     prefix: "RM "
            // },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: InvoiceApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: InvoicesApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = {
            include: [
                {
                    relation: 'receipt',
                    preset: "link"
                },
            ]
        };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return <>{title}<WhiteSpace />{data && <Status status={data?.status} prefix='invoices.status' />}</>;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
            {
                label: "Activity",
                path: path + "/activity"
            },
        ]

    },
    singleButtons: (routeName, params, user, fetch) => {

        return [
            wpOldLink(fetch.data),
        ].filter(Boolean);

    },

};