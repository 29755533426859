import React, { useMemo } from 'react';
import { Col, DataTable, DataTableCell, InputTableRow, Line, ModelLink, Row, useApp } from '@dex/bubl-dash';
import { formatNum, nl2br } from '@dex/bubl-helpers';

const InvoiceLineItems: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const columns = [
        {
            label: "Description",
            key: "description",
        },
        {
            label: "Quantity",
            key: "quantity",
            alignMiddle: true,
        },
        {
            label: "Subtotal",
            key: "value",
            alignEnd: true,
        },
    ];

    return useMemo(() => (

        <>

            <DataTable
                data={null}
                columns={columns}
                className={"mini-table invoice-table"}
            >

                {data.rows.map((row: any, index: number) => (
                    <InputTableRow className={"invoice-line-item"} key={index}>

                        <DataTableCell style={{ width: "60%" }} className={"invoice-line-desc"}>

                            {row.description ? app.formatValue("nl2br", {}, row.description) : ""}

                            <InvoiceLineItemsBreakdown
                                items={row.breakdown_items}
                            />

                        </DataTableCell>

                        <DataTableCell alignMiddle className={"invoice-line-quantity"}>
                            {row.quantity}
                        </DataTableCell>

                        <DataTableCell alignEnd className={"invoice-line-value"}>
                            {formatNum(row.value, 2, "")}
                        </DataTableCell>

                    </InputTableRow>
                ))}

                <InputTableRow>

                    <DataTableCell colSpan={2} alignEnd className="invoice-line-label">
                        Subtotal (Excluding Tax)
                    </DataTableCell>

                    <DataTableCell alignEnd>
                        {formatNum(data.subtotal, 2, "")}
                    </DataTableCell>

                </InputTableRow>

                {!data.disableDeposit &&
                    <InputTableRow>

                        <DataTableCell colSpan={2} alignEnd className="invoice-line-label">
                            Refundable Deposit
                        </DataTableCell>

                        <DataTableCell alignEnd>
                            {formatNum(data.deposit, 2, "")}
                        </DataTableCell>

                    </InputTableRow>
                }

                <InputTableRow>

                    <DataTableCell colSpan={2} alignEnd className="invoice-line-label">
                        <strong>Total</strong>
                    </DataTableCell>

                    <DataTableCell alignEnd>
                        <strong>{formatNum(data.total, 2, "RM ")}</strong>
                    </DataTableCell>

                </InputTableRow>

                <InputTableRow>
                    <DataTableCell colSpan={2} alignEnd className="invoice-line-label">
                    </DataTableCell>

                    <DataTableCell alignEnd>
                    </DataTableCell>
                </InputTableRow>

                {data.receipt_id &&
                    <InputTableRow>

                        <DataTableCell colSpan={2} alignEnd className="invoice-line-label">
                            Refundable Deposit<br /> <ModelLink data={{ id: data.receipt_id }} modelName="OfficialReceipts" />
                        </DataTableCell>

                        <DataTableCell alignEnd>
                            {formatNum(data.receipt.deposit, 2, "RM ")}
                        </DataTableCell>

                    </InputTableRow>
                }

            </DataTable>

        </>

    ), [data]);

}

const InvoiceLineItemsBreakdown: React.FC<any> = (props: any) => {

    const { items } = props;

    if (!items || items.length === 0) return <></>;

    return (

        <>
            <Line top={"small"} bottom={"small"} />

            <Row className='invoice-breakdown-items' gutter={4} edge>

                {items.map((item: any, index: number) => (
                    <React.Fragment key={index}>
                        <Col col={{ xs: 16 }}>
                            {item.description}:
                        </Col>
                        <Col col={{ xs: 8 }} align='end'>
                            {formatNum(item.amount, 2, "")}
                        </Col>
                    </React.Fragment>
                ))}

            </Row>

        </>

    );

}

export default InvoiceLineItems;
export { InvoiceLineItemsBreakdown };