import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import { Button, ButtonRow, ModelLink, Space, useApp } from '@dex/bubl-dash';
import OfficialReceiptsStages from '../Stages/OfficialReceiptsStages';

const OfficialReceiptsSingleView: React.FC<any> = (props: any) => {

    const { data, hash } = props;

    const app = useApp();

    const handlePrintReceipt = useCallback((action) => {

        const baseUrl = app.options.endpoint.slice(0, -4);

        window.open(`${baseUrl}/officialReceipts/${data.id}/officialReceipt/${action}`);


    }, []);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Official Receipt Details"}
                    actions={() => (
                        <ButtonRow>
                            <Button
                                label={"Preview"}
                                type={"faded"}
                                size={'small'}
                                onClick={handlePrintReceipt.bind(null, "preview")}
                            />
                            <Button
                                label={"Print"}
                                type={"light"}
                                size={'small'}
                                onClick={handlePrintReceipt.bind(null, "print")}
                            />
                        </ButtonRow>
                    )}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Date Issued",
                                key: "date_issued",
                                format: "dateDay",
                            },
                            data.merchant_id && {
                                label: "Merchant ID",
                                key: "merchant_id",
                                render: (value, row) => (
                                    <ModelLink data={{ id: value }} modelName="Merchants" />
                                )
                            },
                            data.debtor_id && {
                                label: "Merchant ID",
                                key: "debtor_id",
                                render: (value, row) => (
                                    <ModelLink data={{ id: value }} modelName="Merchants" />
                                )
                            },
                            data.invoice_id && {
                                label: "Invoice No.",
                                key: "invoice_id",
                                render: (value, row) => (
                                    <ModelLink data={{ id: value }} modelName="Invoices" />
                                ),
                            },
                            data.proforma_invoice_id && {
                                label: "Proforma Invoice ID",
                                key: "proforma_invoice_id",
                                render: (value, row) => (
                                    <ModelLink data={{ id: value }} modelName="ProformaInvoices" />
                                )
                            },
                            {
                                label: "Deposit",
                                key: "deposit",
                                format: "number",
                                prefix: "RM "
                            },
                        ].filter(Boolean)}
                        columnB={[
                            {
                                label: "Company Name",
                                key: "company_name",
                            },
                            {
                                label: "Contact Person",
                                key: "person",
                            },
                            {
                                label: "Email",
                                key: "email",
                            },
                            {
                                label: "Phone",
                                key: "phone",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Created Date",
                                key: "created",
                                format: "OnAndBy",
                            },
                            {
                                label: "Billing Address",
                                key: "billing_address",
                                format: "address"
                            }
                        ]}
                    />

                </Panel>

                <Space />

                <Col
                    col={{ xs: 24 }}
                >

                    <OfficialReceiptsStages
                        key={hash}
                        data={data}
                    />

                </Col>

            </Col>

        </Row>

    ), [data, hash]);

}

export default OfficialReceiptsSingleView;
